import './SecondNew.css';
import React, { useEffect } from 'react';


const SecondNew = () => {

    useEffect(() => {
        const scrollOptions = {
            top: 0,
            behavior: 'smooth'
        };

        window.scrollTo(scrollOptions);
    }, []);

    return (
        <section id='secondNew'>
            <div className='secondNew__container'>
                <img src='/images/blog/2.jpg' />
                <h1>Receita Federal exclui o ICMS sobre créditos de PIS/COFINS nas aquisições</h1>

                <div className='secondNew-p-container'>
                    <p>“Na apuração dos créditos da Contribuição para o PIS/Pasep e da Cofins a descontar, o valor do ICMS destacado na Nota Fiscal deve ser excluído da base de cálculo, visto que não compõe o preço da mercadoria.”</p>
                    <p>Esta foi a conclusão da RFB na Solução de Consulta 10 Cosit.</p>
                    <p>Dessa forma os contribuintes sujeitos a apuração não-cumulativa (Lucro Real e equiparados) terão que atentar para excluir da base de calculo do PIS/COFINS o ICMS, na aquisição de mercadorias e bens que dão direito ao crédito.</p>
                    <cite>Fonte: RFB</cite>
                </div>
            </div>
        </section >
    )
}

export default SecondNew;