import './SixthNew.css';
import React, { useEffect } from 'react';


const SixthNew = () => {

    useEffect(() => {
        const scrollOptions = {
            top: 0,
            behavior: 'smooth'
        };

        window.scrollTo(scrollOptions);
    }, []);

    return (
        <section id='sixthNew'>
            <div className='sixthNew__container'>
                <img src='/images/blog/2.jpg' />
                <h1>PIX e Cartões na Mira da Receita: O Que Mudou em 2025 com a IN 2.219/2024 da e-Financeira?</h1>

                <article className='sixthNew-article'>
                    <section>
                        <h2>Incorporação da Decred à e-Financeira</h2>

                        <div>
                            <h3>Antes (IN 1.571/2015):</h3>

                            <ul>
                                <li>A Declaração de Operações com Cartões de Crédito (Decred) era um documento específico, separado da e-Financeira.</li>
                            </ul>
                        </div>

                        <div>
                            <h3>Agora (IN 2.219/2024):</h3>

                            <ul>
                                <li>A Decred foi descontinuada, e suas informações foram incorporadas à e-Financeira. Isso inclui dados de operações realizadas com cartões de crédito, débito, private label e outros meios de pagamento eletrônico.</li>
                            </ul>
                        </div>
                    </section>

                    <section>
                        <h2>Alteração dos Limites de Obrigatoriedade</h2>

                        <div>
                            <h3>Antes (IN 1.571/2015):</h3>

                            <ul>
                                <li>Operações acima de R$ 2.000,00 mensais para pessoas físicas.</li>
                                <li>Operações acima de R$ 6.000,00 mensais para pessoas jurídicas.</li>
                            </ul>
                        </div>

                        <div>
                            <h3>Agora (IN 2.219/2024):</h3>

                            <ul>
                                <li>Operações acima de R$ 5.000,00 mensais para pessoas físicas.</li>
                                <li>Operações acima de R$ 15.000,00 mensais para pessoas jurídicas.</li>
                            </ul>
                        </div>
                    </section>

                    <section>
                        <h2>Ampliação dos Declarantes</h2>

                        <div>
                            <h3>Antes (IN 1.571/2015):</h3>

                            <ul>
                                <li>A obrigatoriedade de entrega estava restrita às instituições financeiras e demais autorizadas a funcionar pelo Banco Central.</li>
                            </ul>
                        </div>

                        <div>
                            <h3>Agora (IN 2.219/2024):</h3>

                            <ul>
                                <li>Incluem-se também outras entidades envolvidas em transações financeiras, como administradoras de meios de pagamento.</li>
                            </ul>
                        </div>
                    </section>

                    <section>
                        <h2>Novos Dados Coletados</h2>

                        <div>
                            <h3>Antes (IN 1.571/2015):</h3>

                            <ul>
                                <li>Apenas dados básicos de contas bancárias (saldos, rendimentos) eram informados.</li>
                            </ul>
                        </div>

                        <div>
                            <h3>Agora (IN 2.219/2024):</h3>

                            <ul>
                                <li>Incluem-se transações realizadas por: Cartões de crédito, débito e private label. Outros instrumentos de pagamento, como boletos e transferências eletrônicas.</li>
                            </ul>
                        </div>
                    </section>

                    <section>
                        <h2>Periodicidade e Prazos de Envio</h2>

                        <div>
                            <h3>Antes (IN 1.571/2015):</h3>

                            <ul>
                                <li>Dados eram enviados semestrais, com prazos fixos.</li>
                            </ul>
                        </div>

                        <div>
                            <h3>Agora (IN 2.219/2024):</h3>

                            <ul>
                                <li>Informações referentes ao primeiro semestre de 2025 deverão ser entregues até agosto de 2025.</li>
                                <li>Dados do segundo semestre de 2025 deverão ser entregues até fevereiro de 2026.</li>
                            </ul>
                        </div>
                    </section>

                    <section>
                        <h2>Uso dos Dados pela Receita Federal</h2>

                        <div>
                            <h3>Antes (IN 1.571/2015):</h3>

                            <ul>
                                <li>Dados eram utilizados principalmente para cruzamento tributário e combate à sonegação.</li>
                            </ul>
                        </div>

                        <div>
                            <h3>Agora (IN 2.219/2024), os dados serão usados para:</h3>

                            <ul>
                                <li>Gerar declarações pré-preenchidas de imposto de renda.</li>
                                <li>Melhorar o gerenciamento de riscos fiscais.</li>
                                <li>Oferecer serviços mais precisos e rápidos aos contribuintes.</li>
                            </ul>
                        </div>
                    </section>

                    <section>
                        <h2>Garantias de Sigilo</h2>

                        <div>
                            <h3>Antes (IN 1.571/2015):</h3>

                            <ul>
                                <li>Dados eram utilizados principalmente para cruzamento tributário e combate à sonegação.</li>
                            </ul>
                        </div>

                        <div>
                            <h3>Mantidas:</h3>

                            <ul>
                                <li>Tanto na IN 1.571/2015 quanto na IN 2.219/2024, o sigilo bancário e fiscal é preservado, seguindo normas legais.</li>
                            </ul>
                        </div>
                    </section>

                    <section>
                        <h2>Conclusão</h2>

                        <p>A IN 2.219/2024 representa um avanço na centralização e eficiência do controle fiscal no Brasil. Embora as informações sobre transações financeiras já fossem enviadas anteriormente por meio de diferentes declarações, a nova norma unifica esses dados na e-Financeira, simplificando o processo e facilitando o acesso da Receita Federal às informações.</p>
                        <p className='article-p-margin'>Além disso, a ampliação dos declarantes e a atualização dos limites de obrigatoriedade refletem a adaptação às novas dinâmicas do mercado, especialmente com o crescimento de meios de pagamento digitais como o PIX. Para contribuintes e empresas, a principal mudança está na centralização dessas informações em um único sistema, o que reforça a importância de atenção ao cumprimento das obrigações fiscais para evitar inconsistências e penalidades.</p>
                    </section>
                </article>
            </div>
        </section>
    )
}

export default SixthNew;