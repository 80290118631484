import './ThirdNew.css';
import React, { useEffect } from 'react';


const ThirdNew = () => {

    useEffect(() => {
        const scrollOptions = {
            top: 0,
            behavior: 'smooth'
        };

        window.scrollTo(scrollOptions);
    }, []);

    const funcionamentoSections = [
        {
            id: 1,
            title: '1 – Prospecção ou Topo do funil de vendas',
            text: 'Esta é a primeira experiência que o lead vai ter com sua empresa. E, como diz o ditado, a primeira impressão é a que fica. Portanto, invista em seu site, transforme-o em uma plataforma amigável. Agora é também o momento de conseguir o máximo de dados possíveis deste usuário. Para isso existem algumas técnicas e, uma delas, é investir em conteúdo de qualidade. Assim, para que o prospect receber um conteúdo de qualidade e atraente, ele deixará alguns dados em sua plataforma (nome, telefone, interesse), cadastrados em formulários. A partir daí, é dado o início da nutrição de leads. Este é o momento de inspirar seu cliente e mostrar a ele que sua empresa pode, realmente, solucionar um problema, uma “dor” que ele tem.',
        },

        {
            id: 2,
            title: '2- Qualificação',
            text: 'O momento da qualificação existe para entender se o cliente realmente está interessado em sua solução. O máximo de informações que pudermos coletar sobre as condições deste prospect em adquirir seu produto, tanto sob o viés da necessidade quanto de recursos financeiros. Para entender essas necessidades, você pode perguntar diretamente a ele em uma ligação telefônica, reunião, formulário de contato. Assim como nas redes sociais! Lembre-se: agora não é o momento de vender, é o tempo certo para entender todos os hábitos e necessidades deste seu prospect para abordá-lo da melhor maneira.',
        },

        {
            id: 3,
            title: '3- Apresentação',
            text: 'Agora é a hora do time de vendas entrar em ação. Já com o background do prospect em mãos, os vendedores conseguem entender o perfil de cada um deles e ofertar exatamente aquilo que é de seu interesse.Ainda cabem perguntas para o futuro cliente. Atente-se para responder às dúvidas que podem surgir durante este período. Mais do que simplesmente falar sobre o produto sem parar.E, tente encaixar o produto ou serviço exatamente naquilo que o seu cliente espera.',
        },

        {
            id: 4,
            title: '4- Amadurecimento',
            text: 'Nesta etapa do funil de vendas, o cliente vai avaliar sua proposta, comparar com a concorrência e refletir sobre prós e contras.Você ainda tem influência nesta fase, acompanhando constantemente o processo sem deixar o momento ‘pesado’.Mas, tente mostrar que você está neste momento junto com seu prospect, auxiliando no momento da decisão. Podendo oferecer testes gratuitos de seu produto ou serviço, para agregar valor à sua venda.',
        },

        {
            id: 5,
            title: '5- Negociação',
            text: 'Esta é a etapa da venda em que os clientes costumam fazer a contraproposta para abaixar os valores oferecidos. Não caia na briga de preços. Saiba o valor da sua expertise e qual será o retorno do seu cliente sobre o investimento feito! Prepare-se para enfrentar questionamentos sobre preços, mas vendedores com experiência sabem que este é o momento em que o conhecimento profundo do serviço oferecido irá garantir avanços no fechamento do negócio. Claro, sem prejudicar o relacionamento construído durante todo este processo. Vá preparado para este momento, com todas os possíveis contrapontos que podem ser levantados.',
        },

        {
            id: 6,
            title: '6- Fechamento de negócio',
            text: 'Ainda não é a hora de assinar o contrato! Planeje e comunique sua entrega, sempre alinhando expectativas entre a empresa e o cliente. Não esconda nada para que consiga fechar o negócio. Seja o mais transparente e claro possível neste momento. Apresente os prazos, resultados esperados, condições de pagamento. Para isso o seu time de vendas deverá ter conhecimento sobre contratos e condições de pagamento. Para auxiliar neste caminho percorrido pelo lead, até o momento do fechamento da compra, o marketing de conteúdo trabalha com a nutrição de conteúdos relevantes para seu prospect. Entenda as necessidades e o que ele procura, assim comece a encaminhar emails, webinars, ebooks, que tenham apelo.',
        },
    ]

    return (
        <section id='thirdNew'>
            <div className='thirdNew__container'>
                <img src='images/blog/3.jpg' />
                <h1>O funil de vendas (PIPELINE) é importante?</h1>
                <p>
                    Também chamado de pipeline, o funil de vendas é um modelo estratégico que mostra a jornada do cliente,
                    desde o primeiro contato dele com a empresa até o fechamento do negócio.
                </p>

                <section className='thirdNew__vantagens'>
                    <h2>Quais as vantagens de se trabalhar com funil de vendas?</h2>
                    <p>
                        Uma das maiores vantagens de se trabalhar com o funil de vendas está no fato de que ao executar essa
                        metodologia, é possível identificar alguns padrões de vendas que se repetem, o que pode ser muito
                        positivo para as empresas. Por exemplo, uma ação para atrair mais clientes para o topo do funil,
                        pode ser medida/avaliada a partir da chegada de mais clientes no topo do funil. Entender como funciona
                        o funil e se organizar a partir dele, contribui para que a gestão se otimize, isto é, as ações se
                        tornam mais assertivas e o foco se volta para as etapas que realmente precisam de mais atenção, o que
                        colabora para que ocorra um salto na produtividade.
                    </p>
                </section>

                <section className='thirdNew__funcionamento'>
                    <h2>Funil de vendas: como funciona cada etapa</h2>
                    {funcionamentoSections.map((section => (
                        <section key={section.id}>
                            <h3>{section.title}</h3>
                            <p>{section.text}</p>
                        </section>
                    )))}
                </section>
            </div>
        </section>
    )
}

export default ThirdNew;